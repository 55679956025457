<template>
  <v-container>
    <v-row>
      <v-col cols="2">
        <h2>事業所増減明細</h2>
      </v-col>
      <v-col cols="3">
      </v-col>
      <v-col cols="4">
      </v-col>

      <!-- <v-col cols="2">
        <v-text-field label="検索"
              dense
              v-model="searchCondition.searchString"
              :disabled="loading"
              :loading="loading"
        ></v-text-field>
      </v-col> -->
    </v-row>

    <v-row>
      <v-col cols="4">
        <div class="flex">
          <DatePicker
            label="検索開始"
            v-model="searchCondition.fromDate"
            format="YYYY/MM/DD"
            selectType="date"
            :loading="loading"
          ></DatePicker>
          <div class="mx-5" >～</div>
          <DatePicker
            label="検索終了"
            v-model="searchCondition.toDate"
            format="YYYY/MM/DD"
            selectType="date"
            :loading="loading"
          ></DatePicker>
        </div>
      </v-col>
      <v-col cols="2">
          <v-btn class="btn_font primary " width="100" v-on:click="loadList()" :disabled="loading"
        >検索</v-btn>
      </v-col>
      <v-col cols="4">
        <v-text-field label="検索"
            dense
            v-model="searchCondition.searchString"
            :disabled="loading"
            :loading="loading"
        ></v-text-field>

      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="updateHistory"
          :search="searchCondition.searchString"
          ref="ref"
          :loading="loading"
          dense
          :mobile-breakpoint="0"
          :disable-pagination='true'
          hide-default-footer
          :fixed-header='true'
          multi-sort
          height="calc(100vh - 250px)"
          locale="ja-jp"
          loading-text="読込中"
          no-data-text="データがありません。"
          class="sticky_table"
        >
          <template v-slot:item="{ item, index }">
            <tr :key="index" >
              <td>{{convDateString(item.changeDate)}}</td>
              <td>{{item.companyName}}<br>[{{item.companyId}}]</td>
              <td>{{item.memberName}}<br>[{{item.memberNo }}]</td>
              <td>{{dispChangeCategory(item.changeCategory)}}</td>
              <td>{{item.remarks}}</td>


              <td class="right">{{dispNumber(item.diffF1)}}</td>
              <td class="right">{{dispNumber(item.diffF2)}}</td>
              <td class="right">{{dispNumber(item.diffF3)}}</td>
              <td class="right">{{dispNumber(item.diffF4)}}</td>
              <td class="right">{{dispNumber(item.diffC1)}}</td>
              <td class="right">{{dispNumber(item.diffC2)}}</td>
              <td class="right">{{dispNumber(item.diffC3)}}</td>
              <td class="right">{{dispNumber(item.diffVolume)}}</td>
              <td class="right">{{dispNumber(item.diffIce)}}</td>
              <td class="right">{{dispNumber(item.diffFrozen)}}</td>

              <td v-if="isCanvas" class="preline">{{dispDecode(item.details)}}</td>
              <!-- <td class="preline">{{dispDecode(item.details)}}</td> -->
              <!-- <td >
                <v-btn small class="mx-1 btn-font primary" v-on:click="show(item)" :height="20" :width="100" :disabled="loading">
                  変更点詳細
                </v-btn>
              </td> -->
            </tr>
          </template>

        </v-data-table>
      </v-col>
    </v-row>


    <!------------------------------------------------------------>
    <!-- 変更点詳細 modal -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="true" :scrollable="true" height="auto" width="50%" id="vm--modal">
      <div class="modal-body">
        <v-row class="mt-5">
          <v-col>
            <v-text-field label="変更日"
                v-model="detail_date"
                dense
                readonly
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field label="会社名"
                v-model="detail_company"
                dense
                readonly
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field label="事業所名"
                v-model="detail_member"
                dense
                readonly
            ></v-text-field>
            </v-col>
          </v-row>

        <v-data-table
          :headers="headers2"
          :items="detail_data"
          :loading="loading"
          dense
          :disable-pagination='true'
          hide-default-footer
          :fixed-header='true'
          multi-sort
          height="calc(100vh - 250px)"
          locale="ja-jp"
          loading-text="読込中"
          no-data-text="データがありません。"
          class="mt-5"
        >
        </v-data-table>
      </div>
    </modal>

  </v-container>
</template>

<script>
import Mixin from "../mixins/const.js";
import Member from "../model/member";
import Message from "../Lib/Message";
import DatePicker from "./common/DatePicker.vue";

import Util from "../Lib/Util";
import Debug from "../Lib/Debug";

export default {
  components: {
    DatePicker,
  },

  mixins: [Mixin],

  data: () => ({
    loading: false,

    // テーブルヘッダー
    headers: [
      // { text: "会員ID", align: "center", sortable: true, value: "memberNo", width: "80px" },
      // { text: "登録番号", align: "center", sortable: true, value: "companyId", width: "80px" },
      { text: "変更日", align: "left", sortable: true, value: "changeDate", width: "120px" },
      { text: "会社名", align: "left", sortable: true, value: "companyName", width: "180px" },
      { text: "事業所名", align: "left", sortable: true, value: "memberName", width: "180px" },

      { text: "変更区分", align: "left", sortable: true, value: "changeCategory", width: "140px" },
      { text: "備考", align: "left", sortable: true, value: "remarks", width: "200px" },
      { text: "Ｆ１級", align: "right", sortable: true, value: "diffF1", width: "80px" },
      { text: "Ｆ２級", align: "right", sortable: true, value: "diffF2", width: "80px" },
      { text: "Ｆ３級", align: "right", sortable: true, value: "diffF3", width: "80px" },
      { text: "Ｆ４級", align: "right", sortable: true, value: "diffF4", width: "80px" },
      { text: "Ｃ１級", align: "right", sortable: true, value: "diffC1", width: "80px" },
      { text: "Ｃ２級", align: "right", sortable: true, value: "diffC2", width: "80px" },
      { text: "Ｃ３級", align: "right", sortable: true, value: "diffC3", width: "80px" },
      { text: "ＦＣ計", align: "right", sortable: true, value: "diffVolume", width: "80px" },
      { text: "製氷", align: "right", sortable: true, value: "diffIce", width: "80px" },
      { text: "凍結", align: "right", sortable: true, value: "diffFrozen", width: "80px" },

      // { text: "変更明細", align: "left", sortable: true, value: "details", width: "160px" },
      // { text: "", sortable: false },
    ],

    headers2: [
      { text: "項目", align: "left", sortable: true, value: "key", width: "120px" },
      { text: "変更前", align: "left", sortable: true, value: "old", width: "180px" },
      { text: "変更後", align: "left", sortable: true, value: "value", width: "180px" },
    ],

    updateHistory: [], // 増減明細

    // 検索条件
    searchCondition: {
      fromDate: '',
      toDate: '',
      screenType: 'MemberList',
      searchString: "",
    },

    detail_text: '',
    detail_date: '',
    detail_company: '',
    detail_member: '',
    detail_data: [],

    isCanvas: false,
  }),

  // ライフサイクルフック
  created: function () {
  },

  mounted: async function () {
    // 日付範囲を計算
    const to_day = new Date();
    const from_day = new Date();
    from_day.setMonth(from_day.getMonth() - 6);
    this.searchCondition.fromDate = Util.convDateString(from_day);
    this.searchCondition.toDate = Util.getToday(to_day);

    // canvasユーザのみ
    this.isCanvas = (this.$store.getters.getUserid == "developer@canvas-works.jp");
    if (this.isCanvas) {
        this.headers.push(
            { text: "変更明細", align: "left", sortable: true, value: "details", width: "400px" }
        );
    }

    this.loadList();
  },

  methods: {
    /** 表示更新 */
    async loadList() {
      Debug.log("function[loadList]");
      this.loading = true;

      // DBから会員一覧取得
      try {
        const res = await Member.getHistoryData(this.searchCondition);
        this.updateHistory = res.data;

        Debug.log2("updateHistory:", this.updateHistory);
        // this.$store.commit('setStoreSearchCondition', this.searchCondition);
      } catch (error) {
        Message.err(error, "増減明細データを取得できませんでした");
      }

      // Debug.log(this.memberList);
      this.loading = false;
    },

    convDateString(str) {
      const dtStr = str.replaceAll("-", "").replaceAll(":", "").replaceAll(" ", "");
      return Util.convDateTimeString(Util.convStringDateNoDelim(dtStr));
    },

    dispDecode(json) {
      const detail = JSON.parse(json);

      let strDetail = [];
      detail.forEach(el => {
        strDetail.push(el["key"] + " : " + el["old"] + " -> " + el["value"]);
      });

      return strDetail.join("\n");
    },

    // 少数以下を四捨五入 + 3桁カンマ
    dispNumber(val) {
      return Math.round(val).toLocaleString();
    },

    dispChangeCategory(code) {
      if (code) {
        return this.changeCategory[code].text;
      } else {
        return '';
      }
    },

    // ------------------------------------------------------------------------
    // モーダル表示用
    show (item) {
      Debug.log('function[show]');

      this.detail_date = item.changeDate;
      this.detail_company = item.companyName;
      this.detail_member = item.memberName;

      this.detail_data= JSON.parse(item.details);

      this.$modal.show('modal-dialog');
    },

    // hide : function () {
    //   Debug.log('function[hide]');
    //   this.$modal.hide('modal-dialog');
    // },


  },

};
</script>

<style scoped>

.flex {
  display: flex;
}

.preline {
  white-space: pre-line;
}


</style>
